import { useCountry } from '@/components/Link'
import { useTenantStrict } from '@/hooks/useTenant'

export const useIntercomIntegration = () => {
  const tenant = useTenantStrict()
  const country = useCountry()

  return (
    tenant?.integrations?.intercom?.regions?.find(
      (r) => r?.countryCode.toLowerCase() === country.toLowerCase(),
    ) || null
  )
}
