import { useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import { useAnalytics } from '@/hooks/useAnalytics'

export default function Analytics() {
  const router = useRouter()
  const analytics = useAnalytics()
  const previousUrlRef = useRef('')

  useEffect(() => {
    if (!analytics) return

    const handleRouteChange = (url: string) => {
      if (url !== previousUrlRef.current) {
        console.log('Page router path changed...')
        analytics.page()
        previousUrlRef.current = url
      }
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [analytics, router.events])

  return null
}
