import countries from '@/generated/countries.json'

export const SUPPORTED_COUNTRIES = ['GB', 'IE', 'NL', 'DE', 'AU', 'SG', 'NZ']
export const SUPPORTED_LANGUAGES = countries
  .filter(({ code }) => SUPPORTED_COUNTRIES.includes(code))
  .flatMap(({ languages }) => languages)
  .filter((language, index, languages) => languages.indexOf(language) === index)

export const SUPPORTED_LOCALES = countries
  .filter(({ code }) => SUPPORTED_COUNTRIES.includes(code))
  .flatMap((country) =>
    country.languages.map((language) => `${language}-${country.code.toLowerCase()}`),
  )

export const DEFAULT_LANGUAGE = 'en'
export const DEFAULT_LOCALE = 'en-gb'

export const UNLOCALISABLE_PATHS = [
  '/api/',
  '/_next/',
  '/favicon.ico',
  '/images/',
  '/graphql',
  '/ws',
  '/feedr-client/',
  '/sitemap.xml',
  '/sitemap-0.xml',
  '/server-sitemap.xml',
  '/revalidate/',
  '/robots.txt',
  '/authentication/callback',
  '/.well-known',
  '/hreflang.json',
  '/sitemaps/',
  '/assets/',
]
