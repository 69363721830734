import '@/styles/globals.css'
import type {} from '@mui/lab/themeAugmentation'
import type { AppProps } from 'next/app'
import appVariables from '@/config'
import { main as legacyTheme } from '@teamfeedr/ui--theme'
import Head from 'next/head'
import { withSplitFactory } from '@splitsoftware/splitio-react'
import { NextComponentType, NextPageContext } from 'next'
import { EmotionCache, CacheProvider as EmotionCacheProvider } from '@emotion/react'
import createEmotionCache from '@/helpers/createEmotionCache'
import { Montserrat } from 'next/font/google'

import appleIcon180 from 'public/apple-icon-180.png'
import appleSplash2048x2732 from 'public/apple-splash-2048-2732.jpg'
import appleSplash2732x2048 from 'public/apple-splash-2732-2048.jpg'
import appleSplash1668x2388 from 'public/apple-splash-1668-2388.jpg'
import appleSplash2388x1668 from 'public/apple-splash-2388-1668.jpg'
import appleSplash1536x2048 from 'public/apple-splash-1536-2048.jpg'
import appleSplash2048x1536 from 'public/apple-splash-2048-1536.jpg'
import appleSplash1668x2224 from 'public/apple-splash-1668-2224.jpg'
import appleSplash2224x1668 from 'public/apple-splash-2224-1668.jpg'
import appleSplash1620x2160 from 'public/apple-splash-1620-2160.jpg'
import appleSplash2160x1620 from 'public/apple-splash-2160-1620.jpg'
import appleSplash1284x2778 from 'public/apple-splash-1284-2778.jpg'
import appleSplash2778x1284 from 'public/apple-splash-2778-1284.jpg'
import appleSplash1170x2532 from 'public/apple-splash-1170-2532.jpg'
import appleSplash2532x1170 from 'public/apple-splash-2532-1170.jpg'
import appleSplash1125x2436 from 'public/apple-splash-1125-2436.jpg'
import appleSplash2436x1125 from 'public/apple-splash-2436-1125.jpg'
import appleSplash1242x2688 from 'public/apple-splash-1242-2688.jpg'
import appleSplash2688x1242 from 'public/apple-splash-2688-1242.jpg'
import appleSplash828x1792 from 'public/apple-splash-828-1792.jpg'
import appleSplash1792x828 from 'public/apple-splash-1792-828.jpg'
import appleSplash1242x2208 from 'public/apple-splash-1242-2208.jpg'
import appleSplash2208x1242 from 'public/apple-splash-2208-1242.jpg'
import appleSplash750x1334 from 'public/apple-splash-750-1334.jpg'
import appleSplash1334x750 from 'public/apple-splash-1334-750.jpg'
import appleSplash640x1136 from 'public/apple-splash-640-1136.jpg'
import appleSplash1136x640 from 'public/apple-splash-1136-640.jpg'

import '../styles/app.css'
import Providers from '@/components/Providers'
import Analytics from '@/components/Segment/PageRouterSegmentAnalytics'

const montserrat = Montserrat({
  subsets: ['latin'],
  display: 'swap',
  variable: '--montserrat-font',
})

export type CustomAppProps = {
  emotionCache?: EmotionCache
}
type Props = AppProps & CustomAppProps

const clientSideEmotionCache = createEmotionCache()
const App = function App({ Component, pageProps, emotionCache = clientSideEmotionCache }: Props) {
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
        <link rel="icon" type="image/x-icon" href="/favicon.ico" />
        {appVariables.ENABLE_PWA && (
          <>
            <link rel="manifest" href="/pwa/manifest.json" />
            <meta name="application-name" content="Feedr" />
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta name="apple-mobile-web-app-status-bar-style" content="default" />
            <meta name="apple-mobile-web-app-title" content="Feedr" />
            <meta
              name="description"
              content="Discover a range of delicious, catered food for office meetings and events. Delivered to your workplace from vendors across London. Workplace lunch, solved."
            />
            <meta name="mobile-web-app-capable" content="yes" />
            <meta name="theme-color" content={legacyTheme.colors.background_dark} />
            <link rel="apple-touch-icon" href={appleIcon180.src} />
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <link
              rel="apple-touch-startup-image"
              href={appleSplash2048x2732.src}
              media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href={appleSplash2732x2048.src}
              media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
            />
            <link
              rel="apple-touch-startup-image"
              href={appleSplash1668x2388.src}
              media="(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href={appleSplash2388x1668.src}
              media="(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
            />
            <link
              rel="apple-touch-startup-image"
              href={appleSplash1536x2048.src}
              media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href={appleSplash2048x1536.src}
              media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
            />
            <link
              rel="apple-touch-startup-image"
              href={appleSplash1668x2224.src}
              media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href={appleSplash2224x1668.src}
              media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
            />
            <link
              rel="apple-touch-startup-image"
              href={appleSplash1620x2160.src}
              media="(device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href={appleSplash2160x1620.src}
              media="(device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
            />
            <link
              rel="apple-touch-startup-image"
              href={appleSplash1284x2778.src}
              media="(device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href={appleSplash2778x1284.src}
              media="(device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
            />
            <link
              rel="apple-touch-startup-image"
              href={appleSplash1170x2532.src}
              media="(device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href={appleSplash2532x1170.src}
              media="(device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
            />
            <link
              rel="apple-touch-startup-image"
              href={appleSplash1125x2436.src}
              media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href={appleSplash2436x1125.src}
              media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
            />
            <link
              rel="apple-touch-startup-image"
              href={appleSplash1242x2688.src}
              media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href={appleSplash2688x1242.src}
              media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
            />
            <link
              rel="apple-touch-startup-image"
              href={appleSplash828x1792.src}
              media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href={appleSplash1792x828.src}
              media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
            />
            <link
              rel="apple-touch-startup-image"
              href={appleSplash1242x2208.src}
              media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href={appleSplash2208x1242.src}
              media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
            />
            <link
              rel="apple-touch-startup-image"
              href={appleSplash750x1334.src}
              media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href={appleSplash1334x750.src}
              media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
            />
            <link
              rel="apple-touch-startup-image"
              href={appleSplash640x1136.src}
              media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href={appleSplash1136x640.src}
              media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
            />
          </>
        )}
      </Head>
      {/* eslint-disable-next-line react/no-unknown-property */}
      <style jsx global>{`
        :root {
          --montserrat-font: ${montserrat.style.fontFamily};
        }
        html {
          font-family: var(--montserrat-font, Montserrat), sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 1.5;
          letter-spacing: 0;
        }
      `}</style>
      <EmotionCacheProvider value={emotionCache}>
        <Providers>
          <Component {...pageProps} />
          <Analytics />
        </Providers>
      </EmotionCacheProvider>
    </>
  )
}

const sdkConfig = {
  core: {
    authorizationKey: appVariables.SPLIT_AUTH_KEY,
    key: 'key',
  },
}

const AppWithSplit = withSplitFactory(sdkConfig)(App) as NextComponentType<
  NextPageContext,
  Partial<Props>
>

export default AppWithSplit
