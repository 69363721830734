import { CloseRounded, EmailRounded, ForumRounded, PhoneRounded } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Stack,
  Typography,
} from '@mui/material'
import React from 'react'

const SupportDialog: React.FC<{
  open: boolean
  onClose: () => void
  onChatClick: () => void
  hasChatSupport: boolean
  billingEntitiesWithPhone: { id: string; countryCode: string; phone: string }[]
  billingEntitiesWithEmail: { id: string; countryCode: string; email: string }[]
}> = ({
  open,
  onClose,
  onChatClick,
  hasChatSupport,
  billingEntitiesWithEmail,
  billingEntitiesWithPhone,
}) => {
  return (
    <Dialog
      open={open}
      maxWidth={billingEntitiesWithPhone.length > 0 && hasChatSupport ? 'md' : 'sm'}
      fullWidth
    >
      <DialogTitle>
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
          Contact us
          <IconButton color="primary" aria-label="Close" onClick={onClose}>
            <CloseRounded />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} py={2}>
          <Paper
            elevation={0}
            sx={{
              width: '100%',
              p: 2,
              bgcolor: 'rgb(250, 250, 250)',
            }}
          >
            <EmailRounded color="primary" sx={{ fontSize: 60 }} />
            <Typography variant="h6" mb={2}>
              Email us
            </Typography>
            <Stack direction="column" spacing={0}>
              {(!billingEntitiesWithEmail || billingEntitiesWithEmail.length === 0) && (
                <Button variant="contained" disabled>
                  Email not available
                </Button>
              )}
              {billingEntitiesWithEmail?.map((entity) => (
                <Stack key={entity.id} direction="row" spacing={0} alignItems="center">
                  <Typography variant="body2">{entity.countryCode}: </Typography>
                  <Button
                    variant="text"
                    href={`mailto:${entity.email}`}
                    sx={{
                      textTransform: 'none',
                    }}
                  >
                    {entity.email}
                  </Button>
                </Stack>
              ))}
            </Stack>
          </Paper>
          {billingEntitiesWithPhone.length > 0 && (
            <Paper
              elevation={0}
              sx={{
                width: '100%',
                p: 2,
                bgcolor: 'rgb(250, 250, 250)',
              }}
            >
              <PhoneRounded color="primary" sx={{ fontSize: 60 }} />
              <Typography variant="h6" mb={2}>
                Call us
              </Typography>

              <Stack direction="column" spacing={0}>
                {billingEntitiesWithPhone.map((entity) => (
                  <Stack key={entity.id} direction="row" spacing={1} alignItems="center">
                    <Typography variant="body2">{entity.countryCode}: </Typography>
                    <Button
                      variant="text"
                      href={`tel:${entity.phone}`}
                      sx={{
                        textTransform: 'none',
                      }}
                    >
                      {entity.phone}
                    </Button>
                  </Stack>
                ))}
              </Stack>
            </Paper>
          )}
          {hasChatSupport && (
            <Paper
              elevation={0}
              sx={{
                width: '100%',
                p: 2,
                bgcolor: 'rgb(250, 250, 250)',
              }}
            >
              <ForumRounded color="primary" sx={{ fontSize: 60 }} />
              <Typography variant="h6" mb={2}>
                Chat live
              </Typography>

              <Stack direction="column" spacing={0}>
                <Button variant="contained" onClick={onChatClick}>
                  Chat now
                </Button>
              </Stack>
            </Paper>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

export default SupportDialog
