import { UNLOCALISABLE_PATHS } from '@/constants/languages'
import config from '@/config'

const getLocalizedHref = (originalHref: string, lang?: string) => {
  if (!lang) return originalHref
  if (!config.SHOULD_FORCE_LOCALE) return originalHref
  // Return if the path is already localized
  if (/^\/[a-z]{2}-[a-z]{2}/i.test(originalHref)) return originalHref
  if (UNLOCALISABLE_PATHS.some((path) => originalHref.startsWith(path))) return originalHref

  return originalHref.replace(/^\//, '/' + lang + '/')
}

export default getLocalizedHref
