'use client'

import { useMemo } from 'react'
import { getDefaultTenant, getTenantFromDomain, getTenantFromWindow } from '@/helpers/tenants'
import { useRouter } from 'next/compat/router'
import { useParams } from 'next/navigation'
import { PrebuildTenantsQuery } from '@/generated/graphql'

export const useTenantStrict = () => {
  const legacyRouter = useRouter()
  const params = useParams()

  const tenant = useMemo(() => {
    let foundTenant: PrebuildTenantsQuery['tenants']['rows'][number] | undefined
    const domain = legacyRouter ? legacyRouter.query.domain : params?.domain

    if (domain) {
      foundTenant = getTenantFromDomain(domain.toString())
    }
    if (!domain && typeof window !== 'undefined') {
      foundTenant = getTenantFromWindow()
    }

    return foundTenant
  }, [legacyRouter, params])

  return tenant
}

const useTenant = () => {
  const strictTenant = useTenantStrict()

  const tenant = useMemo(() => {
    if (!strictTenant) {
      const foundTenant = getDefaultTenant()
      if (!foundTenant) throw new Error('No default tenant found')
      return foundTenant
    } else {
      return strictTenant
    }
  }, [strictTenant])

  return tenant
}

export default useTenant
