import { useCountry } from '@/components/Link'
import { useTenantStrict } from '@/hooks/useTenant'

export const useZendeskIntegration = () => {
  const tenant = useTenantStrict()
  const country = useCountry()

  return (
    tenant?.integrations?.zendesk?.regions?.find(
      (r) => r?.countryCode.toLowerCase() === country.toLowerCase(),
    ) || null
  )
}

export const showZendesk = () => {
  window.zE('messenger', 'open')
}
