import { Amplify } from 'aws-amplify'
import { CookieStorage, ICookieStorageData } from 'amazon-cognito-identity-js'
import { StorageHelper } from '@aws-amplify/core'
import config from '@/config'
import { getTenantFromWindow } from './tenants'

const environmentCookiePart =
  config.FEEDR_ENVIRONMENT.charAt(0).toUpperCase() + config.FEEDR_ENVIRONMENT.slice(1)

class CustomStorage {
  cookieStorage: CookieStorage
  storageHelper: any

  constructor(customCookieConfig: ICookieStorageData) {
    this.cookieStorage = new CookieStorage(customCookieConfig)
    this.storageHelper = new StorageHelper().getStorage()
  }

  setItem(key: string, value: string): string {
    if (key.includes('accessToken')) {
      this.cookieStorage.setItem(`cognitoAccessToken${environmentCookiePart}`, value)
    } else if (key.includes('idToken')) {
      this.cookieStorage.setItem(`cognitoIdToken${environmentCookiePart}`, value)
      this.storageHelper.setItem(key, value)
    } else {
      this.storageHelper.setItem(key, value)
    }

    return key
  }

  getItem(key: string): string {
    if (key.includes('accessToken')) {
      return this.cookieStorage.getItem(`cognitoAccessToken${environmentCookiePart}`)
    } else {
      return this.storageHelper.getItem(key)
    }
  }

  removeItem(key: string): void {
    if (key.includes('accessToken')) {
      this.cookieStorage.removeItem(`cognitoAccessToken${environmentCookiePart}`)
    } else if (key.includes('idToken')) {
      this.cookieStorage.removeItem(`cognitoIdToken${environmentCookiePart}`)
      this.storageHelper.removeItem(key)
    } else {
      this.storageHelper.removeItem(key)
    }
  }

  clear(): void {
    this.cookieStorage.clear()
    this.storageHelper.clear()
  }
}

export const initAmplify = () => {
  const foundTenant = getTenantFromWindow()

  if (!foundTenant) {
    throw new Error(`Failed to find tenant for domain ${window.location.hostname}`)
  }

  const siteUrl =
    config.APP_VERSION === 'localhost' ? config.SITE_URL : `https://${foundTenant.domain}`

  const cookieConfig: ICookieStorageData = {
    domain: window.location.hostname,
    secure: !siteUrl?.includes('localhost'),
    path: '/',
    expires: 365,
  }

  Amplify.configure({
    Auth: {
      region: 'eu-west-1',
      userPoolId: foundTenant.cognitoConfiguration.userPoolId,
      userPoolWebClientId: foundTenant.cognitoConfiguration.userPoolClientId,
      mandatorySignIn: false,
      authenticationFlowType: 'USER_PASSWORD_AUTH',
      oauth: {
        domain: foundTenant.cognitoConfiguration.userPoolDomain,
        scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: `${siteUrl}/authentication/callback`,
        redirectSignOut: siteUrl,
        responseType: 'code',
      },
      storage: new CustomStorage(cookieConfig),
    },
  })
}
