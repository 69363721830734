
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AlertFilter": [
      "AlertFilterBoolean",
      "AlertFilterString"
    ],
    "AlertFilterBase": [
      "AlertFilterBoolean",
      "AlertFilterString"
    ],
    "AlertTypeFilter": [
      "AlertTypeFilterAccount",
      "AlertTypeFilterBoolean",
      "AlertTypeFilterCountry",
      "AlertTypeFilterDropdown"
    ],
    "AlertTypeFilterBase": [
      "AlertTypeFilterAccount",
      "AlertTypeFilterBoolean",
      "AlertTypeFilterCountry",
      "AlertTypeFilterDropdown"
    ],
    "CCMenuDebugTest": [
      "CCMenuDebugTestAllocations",
      "CCMenuDebugTestAvailabilities",
      "CCMenuDebugTestCCSupplierOrder",
      "CCMenuDebugTestClusterAssignment"
    ],
    "CheckoutResponse": [
      "GmCheckoutErrors",
      "PayByCardResponse",
      "PayByInvoiceResponse"
    ],
    "CombinedInvoice": [
      "CCInvoice",
      "FlexInvoice",
      "FlexInvoiceAllTypes",
      "GMInvoice"
    ],
    "CombinedVendorStatement": [
      "VendorFlexInvoice",
      "VendorFlexInvoiceAllTypes",
      "VendorStatement"
    ],
    "GmServiceLocation": [
      "AccountLocation",
      "Address",
      "NewAccountLocation"
    ],
    "Invoice": [
      "CCInvoice",
      "GMInvoice"
    ],
    "NotificationEvent": [
      "NotificationEventEmail",
      "NotificationEventPush"
    ]
  }
};
      export default result;
    