import NextLink from 'next/link'
import { useRouter as useNextRouter } from 'next/compat/router'
import { ComponentProps, ComponentType, forwardRef, useMemo } from 'react'
import getLocalizedHref from './getLocalizedHref'
import { CountryCode, Language } from '@/generated/graphql'
import { useParams, useRouter as useNextAppRouter } from 'next/navigation'
import { NextRouter } from 'next/router'
import qs from 'query-string'
import { ParsedUrlQuery } from 'querystring'
import { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime'

type Props = ComponentProps<typeof NextLink>

const hasLocale = (url: string) => /^\/[a-z]{2}-[a-z]{2}/i.test(url.toLowerCase())

export const useLocale = () => {
  // APAC:
  // return 'en-au'
  const legacyRouter = useNextRouter()
  const params = useParams()
  if (params?.lang?.toString()) return params.lang.toString()
  if (!legacyRouter) return undefined

  return (
    legacyRouter.query.lang?.toString() ||
    (legacyRouter.asPath.match(/^\/([a-z]{2}-[a-z]{2})\//i) || [])[1]?.toLowerCase()
  )
}

export const useLocaleLink = () => {
  const lang = useLocale()

  return (href: string) => getLocalizedHref(href, lang)
}

export const useCountry = () => {
  const locale = useLocale()
  return (locale?.split('-')[1]?.toUpperCase() as CountryCode) || CountryCode.Gb
}

export const useLanguage = () => {
  const locale = useLocale()
  return (locale?.split('-')[0] as Language) || Language.En
}

export const useRouter = () => {
  const router = useNextRouter()
  const lang = useLocale()

  if (!router)
    throw new Error('Attempting to use useRouter in app directory. Use useAppRouter instead.')

  const push: NextRouter['push'] = async (url) => {
    let newUrl = url
    if (typeof newUrl === 'string') {
      newUrl = getLocalizedHref(newUrl, lang)
    } else if (newUrl.pathname) {
      newUrl.pathname = getLocalizedHref(newUrl.pathname, lang)
    }
    return router.push(newUrl)
  }

  const replace: NextRouter['replace'] = async (url) => {
    let newUrl = url
    if (typeof newUrl === 'string') {
      newUrl = getLocalizedHref(newUrl, lang)
    } else if (newUrl.pathname) {
      newUrl.pathname = getLocalizedHref(newUrl.pathname, lang)
    }
    return router.replace(newUrl)
  }

  // Note: In recent version of Nextjs, query object no longer contains search query params
  const legacyQuery = useMemo(() => {
    return qs.parse(router.asPath.split(/\?/)[1]) as ParsedUrlQuery
  }, [])

  return {
    ...router,
    legacyQuery,
    push,
    localeReplace: replace,
  }
}

export const useAppRouter = () => {
  const router = useNextAppRouter()
  const locale = useLocale()

  const push: AppRouterInstance['push'] = (url, options) => {
    if (hasLocale(url)) return router.push(url, options)
    return router.push(getLocalizedHref(url, locale), options)
  }

  const replace: AppRouterInstance['replace'] = (url, options) => {
    if (hasLocale(url)) return router.replace(url, options)
    return router.replace(getLocalizedHref(url, locale), options)
  }

  return {
    ...router,
    push,
    replace,
  }
}

const Link = ({ href, ...rest }: Props, ref: Props['ref']) => {
  const lang = useLocale()

  const localizedHref =
    typeof href === 'string'
      ? getLocalizedHref(href, lang)
      : href.pathname != null
        ? { ...href, pathname: getLocalizedHref(href.pathname, lang) }
        : href

  return <NextLink ref={ref} href={localizedHref} {...rest} />
}

export default forwardRef(Link)

export const withLocale =
  <P extends object>(
    ChildComponent: ComponentType<
      P & {
        locale?: string
      }
    >,
  ): ComponentType<P> =>
  // eslint-disable-next-line react/display-name
  (props: P) => {
    const locale = useLocale()
    return <ChildComponent {...props} locale={locale} />
  }

export const useIsNewRouter = () => {
  const legacyRouter = useNextRouter()
  return !legacyRouter
}
