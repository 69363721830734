import { DEFAULT_TENANT_ID } from '@/constants/tenant'
import { TenantFromPrebuildFragment } from '@/generated/graphql'
import tenants from '@/generated/tenants.json'

export const getDefaultTenant = (): TenantFromPrebuildFragment => {
  return tenants.find((t) => t.id === DEFAULT_TENANT_ID) as TenantFromPrebuildFragment
}

export const getTenantFromDomain = (domain: string): TenantFromPrebuildFragment | undefined => {
  const foundTenant =
    domain.includes('localhost') || domain.includes('teamfeedr.vercel.app')
      ? getDefaultTenant()
      : (tenants.find((t) => t.domain === domain) as TenantFromPrebuildFragment)

  return foundTenant
}

export const getTenantFromWindow = (): TenantFromPrebuildFragment | undefined => {
  return getTenantFromDomain(window.location.hostname)
}

export const getPageTenantSuffix = (page: string, domain: string): string => {
  const tenant = getTenantFromDomain(domain)

  const tenantName = tenant?.name

  let pageName = page
  if (tenantName) {
    pageName = `${page} | ${tenantName}`
  }

  return pageName
}

export const getTenantLinkComponentRule = (
  uri: string,
  domain: string,
  useRedirect = false,
): { uri: string; displayComponent: boolean } => {
  const tenant = getTenantFromDomain(domain)

  const restriction = tenant?.uriRestrictedAccessRules.find((r) => new RegExp(r.uri).test(uri))

  if (!restriction) return { uri, displayComponent: true }

  if (restriction.redirectUri && useRedirect) {
    return { uri: restriction.redirectUri, displayComponent: true }
  }

  return { uri, displayComponent: false }
}
