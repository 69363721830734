import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  NormalizedCacheObject,
  from,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import cache from './cache'
import { Auth } from 'aws-amplify'
import { GraphQLFormattedError } from 'graphql'

const graphqlUri =
  typeof window === 'undefined' ? process.env.NEXT_PUBLIC_GRAPHQL_URL : '/api/graphql'

const httpLink = createHttpLink({
  uri: graphqlUri,
  credentials: 'include',
})

export const isUnauthenticatedGraphQLError = (error: GraphQLFormattedError) =>
  error.extensions?.code === 'UNAUTHENTICATED'

export const createAuthLink = () =>
  setContext(async (_, { headers }) => {
    let token = ''
    if (typeof window !== 'undefined') {
      const { state } = JSON.parse(localStorage.getItem('state') || '{}')
      token = state?.authState?.token || state?.vendorAuthState?.token || ''
    }

    try {
      await Auth.currentSession()
    } catch (_) {}

    return {
      headers: { ...headers, authorization: `Bearer ${token}` },
    }
  })

const sharedAuthLink = createAuthLink()

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'x-api-key':
        typeof window === 'undefined'
          ? undefined
          : new URLSearchParams(window.location.search).get('apiKey') || undefined,
    },
  }
})

const createApolloClient = () =>
  new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: from(typeof window === 'undefined' ? [httpLink] : [sharedAuthLink, authLink, httpLink]),
    cache: new InMemoryCache(cache),
    name: 'fusion',
    version: process.env.NEXT_PUBLIC_COMMIT_SHA || 'localhost',
  })

let apolloClient: ApolloClient<NormalizedCacheObject>

export const apolloInit = () => {
  const _apolloClient = apolloClient ?? createApolloClient()
  if (typeof window === 'undefined') return _apolloClient
  if (!apolloClient) apolloClient = _apolloClient
  return _apolloClient
}
