import { useTenantStrict } from '@/hooks/useTenant'
import React, { ReactNode, createContext, useContext, useState } from 'react'
import { IntercomProvider, useIntercom } from 'react-use-intercom'
import { usePathname } from 'next/navigation'
import Script from 'next/script'
import { showZendesk, useZendeskIntegration } from './zendesk'
import { useIntercomIntegration } from './intercom'
import SupportDialog from './SupportDialog'

type IdentifyProps = {
  name?: string
  email?: string
  userId?: string
}

type SupportContext = {
  show: () => void
  showDialog: () => void
  identify: (props: IdentifyProps) => void
  showNewMessage: (message?: string) => void
}

const ModalContext = createContext<SupportContext>({
  show: () => {},
  showDialog: () => {},
  identify: () => {},
  showNewMessage: () => {},
})

const excludedPaths = [
  /^\/[a-z]{2}-[a-z]{2}\/admin(\/.*)?/,
  /^\/[a-z]{2}-[a-z]{2}\/invoicing(\/.*)?/,
]
const useIsExcludedPath = () => {
  const pathname = usePathname()

  return !pathname || excludedPaths.some((path) => pathname.match(path))
}

const SupportProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const isExcludePath = useIsExcludedPath()

  const intercom = useIntercom()
  const intercomIntegration = useIntercomIntegration()
  const zendeskIntegration = useZendeskIntegration()
  const tenant = useTenantStrict()
  const [isDialogVisible, setIsDialogVisible] = useState(false)

  const billingEntitiesWithEmail =
    tenant?.billingEntities?.filter(
      (entity): entity is typeof entity & { email: string } => !!entity.email,
    ) || []
  const billingEntitiesWithPhone =
    tenant?.billingEntities?.filter(
      (entity): entity is typeof entity & { phone: string } => !!entity.phone,
    ) || []

  const hasChatSupport = !!intercomIntegration || !!zendeskIntegration

  const showDialog = () => {
    setIsDialogVisible(true)
  }

  const identify = ({ name, email, userId }: IdentifyProps) => {
    if (intercomIntegration) {
      intercom.boot({
        name,
        email,
        userId,
      })
    }
  }

  const show = () => {
    // This path is an excluded path so integrations are not available
    if (isExcludePath) {
      setIsDialogVisible(true)
      return
    }

    try {
      if (intercomIntegration) {
        intercom.show()
        return
      }

      if (zendeskIntegration) {
        showZendesk()
        return
      }
    } catch (e) {}

    setIsDialogVisible(true)
  }

  const showNewMessage = (message?: string) => {
    // This path is an excluded path so integrations are not available
    if (isExcludePath) {
      setIsDialogVisible(true)
      return
    }

    try {
      if (intercomIntegration) {
        intercom.showNewMessage(message)
        return
      }

      if (zendeskIntegration) {
        showZendesk()
        return
      }
    } catch (e) {}

    setIsDialogVisible(true)
  }

  return (
    <ModalContext.Provider value={{ show, showDialog, identify, showNewMessage }}>
      {children}
      <SupportDialog
        open={isDialogVisible}
        onClose={() => setIsDialogVisible(false)}
        onChatClick={() => {
          setIsDialogVisible(false)
          show()
        }}
        hasChatSupport={hasChatSupport}
        billingEntitiesWithEmail={billingEntitiesWithEmail}
        billingEntitiesWithPhone={billingEntitiesWithPhone}
      />
    </ModalContext.Provider>
  )
}

export const useSupport = () => useContext(ModalContext)

const WrappedSupportProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const isExcludePath = useIsExcludedPath()

  const intercomIntegration = useIntercomIntegration()
  const zendeskIntegration = useZendeskIntegration()

  return (
    <IntercomProvider
      appId={intercomIntegration?.id || ''}
      autoBoot={!!intercomIntegration && !isExcludePath}
    >
      <SupportProvider>{children}</SupportProvider>
      {!!zendeskIntegration && !isExcludePath && (
        <Script
          async
          id="ze-snippet"
          src={`https://static.zdassets.com/ekr/snippet.js?key=${zendeskIntegration.id}`}
        />
      )}
    </IntercomProvider>
  )
}

export default WrappedSupportProvider
