import useTenant from './useTenant'
import { AnalyticsBrowser } from '@segment/analytics-next'

export function useAnalytics() {
  const tenant = useTenant()

  const writeKey = tenant.integrations?.segment?.clientWriteKey

  if (!writeKey) return

  return AnalyticsBrowser.load({
    writeKey,
  })
}
