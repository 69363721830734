import { schema, AppVariables } from './types'

export const getUnbounceSafeLink = (path: string): string =>
  path.endsWith('/') ? path : `${path}/`

const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL
const URI_FOOD_DELIVERY = '/food-delivery-london'

const getConfig = schema.parse(
  {
    FEEDR_ENVIRONMENT: process.env.NEXT_PUBLIC_FEEDR_ENVIRONMENT,
    APP_VERSION: process.env.NEXT_PUBLIC_APP_VERSION,
    DATADOG_APPLICATION_ID: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
    DATADOG_CLIENT_TOKEN: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
    SITE_URL,
    GRAPHQL_URL: process.env.NEXT_PUBLIC_GRAPHQL_URL,
    API_URL: process.env.NEXT_PUBLIC_API_URL,
    SPLIT_AUTH_KEY: process.env.NEXT_PUBLIC_SPLIT_AUTH_KEY || '',
    URI_FOOD_DELIVERY,
    ABOUT_LINK: getUnbounceSafeLink('/about/'),
    URI_GROUP_MEALS: '/office-catering/vendors',
    URI_CLOUD_CANTEEN: '/daily-office-meals',
    URI_OFFICE_MANAGER: '/easy-office-catering',
    URI_HR_MANAGER: '/employee-food-perk',
    URI_EMPLOYEES: '/healthy-work-lunches',
    URI_CANTEEN: '/canteen',
    GM_MICRO_FRONTEND_URL: `${SITE_URL || ''}/office-catering/vendors`,
    URI_HOME_DELIVERY: `${getUnbounceSafeLink('/home-delivery/')}`,
    URI_LOCATION_LONDON: `${getUnbounceSafeLink('/london/')}`,
    URI_LOCATION_MANCHESTER: `${getUnbounceSafeLink('/manchester/')}`,
    URI_LOCATION_DUBLIN: `${getUnbounceSafeLink('/dublin/')}`,
    URI_LOCATION_EDINBURGH: `${getUnbounceSafeLink('/edinburgh/')}`,
    URI_FOOD_DELIVERY_SUPPLIERS: `${SITE_URL || ''}/office-catering/vendors`,
    URI_FOOD_DELIVERY_PARTNERS: `${URI_FOOD_DELIVERY}/partners`,
    URI_FOOD_DELIVERY_RECOMMENDED_PACKAGES: `${URI_FOOD_DELIVERY}/recommended-packages`,
    COMMIT_SHA: process.env.NEXT_PUBLIC_COMMIT_SHA || 'localhost',
    CLOUDFLARE_OPTIMISE_IMAGE: process.env.NEXT_PUBLIC_CLOUDFLARE_OPTIMISE_IMAGE === 'true',
    ENABLE_PWA: process.env.NEXT_PUBLIC_ENABLE_PWA === 'true',
    SHOULD_FORCE_LOCALE: process.env.NEXT_PUBLIC_SHOULD_FORCE_LOCALE === 'true' || false,
    MAPBOX_API_KEY:
      process.env.NEXT_PUBLIC_MAPBOX_API_KEY ||
      'pk.eyJ1IjoiZmVlZHIiLCJhIjoiY2tuaWthdG5yMG9tczJvcGdjN2Y3a2MxZSJ9.B7_WgQ_QeiWY_IwxdE5CfA',
    TZ: process.env.TZ || 'UTC',
  } as AppVariables,
  {
    errorMap: (_issue, { defaultError }) => ({
      message: `App variable validation failed: ${defaultError}`,
    }),
  },
)

export default getConfig
